const Gumshoe = require('gumshoejs')
const device = require('current-device').default
const axios = require('axios').default;

require('./styles/main.scss')
require('slick-carousel')



class PubSub {
  constructor () {
    this.dispatcher = $({})
  }

  emit(name, ...value) {
    this.dispatcher.trigger(name, value)
  }

  on(name, fn) {
    this.dispatcher.on(name, (_, ...args) => fn(...args))
  }
}

const SE = {}
const pubSub = new PubSub()

const isDesktop = () => device.desktop()
const isMobile = () => window.innerWidth <= 1280

const rules = {
  email: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  name: /^.+$/,
  lastName: /^.+$/,
  message: /^.+$/,
  phone: /^([+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*)?$/,
}

const validations = { email: false, name: false, lastName: false, message: false, phone: true }

jQuery(function() {
  window.grids = _ => $('.page-section, .carousel-wrapper, .form').addClass('debug-grid')

  Gumshoe('.site-header .site-nav .links .link-item a');

  loadSiteElements()

  initGumshoe()
  centerFloorplansOnInit()
  initFloorplansCarousel()
  BindProjectCarousel()
  initProjectCarousel()

  setUpStickyMenuButton()
  setUpCloseMenuButton()
  setUpMenuLinks()
  setUpSiteMenu()

  setUpSiteThanksModal()
  setUpThanksModalSendAnotherButton()
  setUpSendMail()
  initContactForm()
  setUpContactSendButton()

  initEffects()
})

const loadSiteElements = () => {
  SE.body = $(document.body)
  SE.siteMenuOpenButton = $('#siteMenuOpenButton')
  SE.siteMenuCloseButton = $('#siteMenuCloseButton')
  SE.siteMenuLinks = $('.site-menu-links a')
  SE.projectCarousel = $('.project-section .carousel')
  SE.projectCarouselCurrentSlide = $('.project-section .carousel-wrapper .current-slide')
  SE.projectCarouselTotalSlide = $('.project-section .carousel-wrapper .total-slide')
  SE.floorplansCarousel = $('.floorplans-section .carousel')
  SE.floorplansCarouselSlides = $('.floorplans-section .carousel .floorplan-pan')
  SE.titleLabel = $('.site-sticky-desktop-menu .title a')
  SE.stickyMenu = $('.site-sticky-desktop-menu')
  SE.siteMenu = $('#siteMenu')
  SE.siteThanksModal = $('#siteThanksModal')
  SE.siteThanksModalSendAnotherButton = $('#siteThanksModalSendAnotherButton')
  SE.siteContactForm = $('#siteContactForm')
  SE.siteContactNameInput = $('#siteContactForm #name')
  SE.siteContactLastNameInput = $('#siteContactForm #lastName')
  SE.siteContactPhoneInput = $('#siteContactForm #phone')
  SE.siteContactEmailInput = $('#siteContactForm #email')
  SE.siteContactMessageInput = $('#siteContactForm #message')
  SE.siteContactSendButton = $('#siteContactSendButton')
  SE.siteContactErrorsMessage = $('#siteContactErrorsMessage')
  SE.lifeStyleCompositionElementA = $('.lifestyle-section .composition-a .composition-element')
  SE.lifeStyleCompositionElementB = $('.lifestyle-section .composition-b .composition-element')
  SE.lifeStyleCompositionElementC = $('.lifestyle-section .composition-c .composition-element')
  SE.lifeStyleCompositionElementD = $('.lifestyle-section .composition-d .composition-element')
  SE.lifeStyleCompositionElementE = $('.lifestyle-section .composition-e .composition-element')
  SE.lifeStyleCompositionElementF = $('.lifestyle-section .composition-f .composition-element')
  SE.amenitiesCompositionElementA = $('.amenities-section .composition-a .composition-element')
  SE.amenitiesCompositionElementBa = $('.amenities-section .composition-b .composition-element .item-a')
  SE.amenitiesCompositionElementBb = $('.amenities-section .composition-b .composition-element .item-b')
  SE.amenitiesCompositionElementBc = $('.amenities-section .composition-b .composition-element .item-c')
  SE.amenitiesCompositionElementBd = $('.amenities-section .composition-b .composition-element .item-d')
  SE.amenitiesCompositionElementBf = $('.amenities-section .composition-b .composition-element .item-f')
  SE.amenitiesCompositionElementBg = $('.amenities-section .composition-b .composition-element .item-g')
  SE.amenitiesCompositionElementBh = $('.amenities-section .composition-b .composition-element .item-h')
  SE.parallaxElements = $('.parallax-element')
}

const setUpStickyMenuButton = () => {
  SE.siteMenuOpenButton.click( _ => {
    _.preventDefault()
    pubSub.emit('siteMenu/open')
  })
}

const setUpThanksModalSendAnotherButton = () => {
  SE.siteThanksModalSendAnotherButton.click(_ => {
    _.preventDefault()
    pubSub.emit('siteThanksModal/close')
  })
}

const setUpContactSendButton = () => {
  SE.siteContactSendButton.click(_ => {
    _.preventDefault()
    showErrors()
    if (formIsValid()) {
      hideErrorsMessage()
      pubSub.emit('mail/send')
    } else {
      showErrorsMessage()
      const input = $(SE.siteContactForm).find('.error')
      input.removeClass('animate')
      setTimeout(() => input.addClass('animate'), 0)
    }
  })
}

const setUpCloseMenuButton = () => {
  SE.siteMenuCloseButton.click( _ => {
    _.preventDefault()
    pubSub.emit('siteMenu/close')
  })
}

const setUpMenuLinks = () => {
  SE.siteMenuLinks.click( _ => {
    pubSub.emit('siteMenu/close')
  })
}

const setUpSiteMenu = () => {
  pubSub.on('siteMenu/close', () => siteMenuClose() && siteScrollEnabled())
  pubSub.on('siteMenu/open', () => siteMenuOpen() && siteScrollDisabled())
}

const setUpSiteThanksModal = () => {
  pubSub.on('siteThanksModal/close', siteThanksModalClose)
  pubSub.on('siteThanksModal/open', siteThanksModalOpen)
}

const setUpSendMail = () => {
  pubSub.on('mail/send', sendMail)
}

const sendMail = () => {
  grecaptcha.ready(function () {
    grecaptcha.execute('6Lczy70UAAAAAMbSUDzX5EGrwvt5pCuFcrvns_Pj', {
      action: 'homepage'
    }).then(function (token) {
      const baseUrl = window.location.origin
      axios.post(`${baseUrl}/form.php`, {
          ...getContactFields(),
          token
        })
        .then(function (response) {
          if (response.data.status == 200) {
            pubSub.emit('siteThanksModal/open')
          } else if (response.data.status == 429) {
            pubSub.emit('siteThanksModal/open')
          } else {
            console.log('Server Error')
          }
        })
        .catch(function (error) {
          console.log('Server Error')
        });
    });
  });
}

const getContactFields = () => ({
  name: SE.siteContactNameInput.val(),
  lastName: SE.siteContactLastNameInput.val(),
  phone: SE.siteContactPhoneInput.val(),
  email: SE.siteContactEmailInput.val(),
  message: SE.siteContactMessageInput.val()
})

const centerFloorplansOnInit = () => {
  const delayedCenter = delayed((event, slick) => {
    const parentHeight = SE.floorplansCarouselSlides.parent()[0].clientHeight
    const parentWidth = SE.floorplansCarouselSlides.parent()[0].clientWidth
    SE.floorplansCarouselSlides.each((index, element) => {
      $(element).find('img').ready(() => {
        let slideHeight = element.scrollHeight
        let slideWidth = element.scrollWidth

        element.scrollTop = (slideHeight - parentHeight) / 2
        element.scrollLeft = 100
      })
    })
  })
  SE.floorplansCarousel.on('init', delayedCenter)
}

const siteMenuOpen = _ => SE.siteMenu.addClass('menu--opened')
const siteMenuClose = _ => SE.siteMenu.removeClass('menu--opened')

const siteScrollDisabled = _ => {
  SE.body.addClass('scroll-disabled')
}
const siteScrollEnabled = _ => SE.body.removeClass('scroll-disabled')

const siteThanksModalOpen = _ => SE.siteThanksModal.addClass('modal--opened')
const siteThanksModalClose = _ => SE.siteThanksModal.removeClass('modal--opened')

const [arrows, draggable, swipe] = [!isMobile(), false, false]

const initFloorplansCarousel = _ => $(SE.floorplansCarousel).slick({draggable, swipe})
const initProjectCarousel = _ => $(SE.projectCarousel).slick({arrows})
const BindProjectCarousel = _ => {
  const updateIndicator = (total, current) => {
    SE.projectCarouselTotalSlide.text(total)
    SE.projectCarouselCurrentSlide.text(current)
  }
  SE.projectCarousel.on("init", (event, slick, currentSlide) => {
    updateIndicator(slick.slideCount, currentSlide ? currentSlide + 1 : 1)
  })
  SE.projectCarousel.on("afterChange", (event, slick, currentSlide) => {
    updateIndicator(slick.slideCount, currentSlide ? currentSlide + 1 : 1)
  })
}

const updateSectionTitle = (name, text) => SE.titleLabel.text(text).attr('href', `#${name}`)
const DeactivateMenuLink = _ => SE.siteMenuLinks.removeClass('active')
const ActivateMenuLink = (name) => SE.siteMenuLinks.filter(`.${name}`).addClass('active')

const initGumshoe = () => {

  document.addEventListener('gumshoeActivate', function (event) {
    const name = event.detail.link.href.split('#')[1]
    const text = event.detail.link.text
    updateSectionTitle(name, text)
    ActivateMenuLink(name)
  }, false);

  document.addEventListener('gumshoeDeactivate', function (event) {
    DeactivateMenuLink()
    siteThanksModalClose()
  }, false);
}

const showErrors = () => {
  SE.siteContactForm.find('.invalid').addClass('error')
}

const showErrorsMessage = () => {
  SE.siteContactErrorsMessage.addClass('show')
}

const hideErrorsMessage = () => {
  SE.siteContactErrorsMessage.removeClass('show')
}

const formIsValid = () => {
  for(let i in validations) {
    if (validations[i] === false) return false
  }
  return true
}

const inputValidation = ({ target }) => {
  const {name, value} = target

  const valid = rules[name].test(value)

  validations[name] = valid

  if (valid) {
    $(target).parent().removeClass('error invalid')
  } else {
    $(target).parent().addClass('error invalid')
  }

  return valid
}

const initContactForm = () => {
  const inputs = $([SE.siteContactNameInput,
    SE.siteContactLastNameInput, SE.siteContactPhoneInput,
    SE.siteContactEmailInput, SE.siteContactMessageInput])

  inputs.each((_, item) => item.keyup(inputValidation))
}

const initEffects = () => {
  const effects = [
    {
      el: SE.lifeStyleCompositionElementA,
      delta: window.innerHeight * 0.7,
      startClass: 'enterFromRight',
      activeClass: 'enter--active',
      active: false
    },
    {
      el: SE.lifeStyleCompositionElementB,
      delta: window.innerHeight * 0.7,
      startClass: 'enterFromLeft',
      activeClass: 'enter--active',
      active: false
    },
    {
      el: SE.lifeStyleCompositionElementC,
      delta: window.innerHeight * 0.7,
      startClass: 'enterFromLeft',
      activeClass: 'enter--active',
      active: false
    },
    {
      el: SE.lifeStyleCompositionElementD,
      delta: window.innerHeight * 0.7,
      startClass: 'enterFromRight',
      activeClass: 'enter--active',
      active: false
    },
    {
      el: SE.lifeStyleCompositionElementE,
      delta: window.innerHeight * 0.7,
      startClass: 'enterFromLeft',
      activeClass: 'enter--active',
      active: false
    },
    {
      el: SE.lifeStyleCompositionElementF,
      delta: window.innerHeight * 0.7,
      startClass: 'enterFromBottom',
      activeClass: 'enter--active',
      active: false
    },
    {
      el: SE.amenitiesCompositionElementA,
      delta: window.innerHeight * 0.7,
      startClass: 'enterFromLeft',
      activeClass: 'enter--active',
      active: false
    },
    {
      el: SE.amenitiesCompositionElementBa,
      delta: window.innerHeight * 0.7,
      startClass: 'enterFromRight',
      activeClass: 'enter--active',
      active: false
    },
    {
      el: SE.amenitiesCompositionElementBb,
      delta: window.innerHeight * 0.7,
      startClass: 'enterFromRight',
      activeClass: 'enter--active',
      active: false
    },
    {
      el: SE.amenitiesCompositionElementBc,
      delta: window.innerHeight * 0.7,
      startClass: 'enterFromRight',
      activeClass: 'enter--active',
      active: false
    },
    {
      el: SE.amenitiesCompositionElementBd,
      delta: window.innerHeight * 0.7,
      startClass: 'enterFromRight',
      activeClass: 'enter--active',
      active: false
    },
    {
      el: SE.amenitiesCompositionElementBf,
      delta: window.innerHeight * 0.7,
      startClass: 'enterFromRight',
      activeClass: 'enter--active',
      active: false
    },
    {
      el: SE.amenitiesCompositionElementBg,
      delta: window.innerHeight * 0.7,
      startClass: 'enterFromRight',
      activeClass: 'enter--active',
      active: false
    },
    {
      el: SE.amenitiesCompositionElementBh,
      delta: window.innerHeight * 0.7,
      startClass: 'enterFromRight',
      activeClass: 'enter--active',
      active: false
    }
  ]

  window.onload = function () {

    const initEffects = () => {
      effects.forEach((effect) => {
        effect.el.addClass(effect.startClass)
      })
    }

    const updateEffects = () => {
      window.requestAnimationFrame(updateEffects)
      effects.forEach((effect) => {
        if (effect.active) return
        if (effect.el[0].getBoundingClientRect().top - effect.delta <= 0)
        effect.el.addClass(effect.activeClass)
      })
    }

    const updateParallax = () => {
      window.requestAnimationFrame(updateParallax)
      SE.parallaxElements.each((index, element) => {
        const top = element.getBoundingClientRect().top
        const delta = this.innerHeight / 2 - top
        $(element).css('transform', `translateY(${delta/10}px)`)
      })
    }

    initEffects()
    window.requestAnimationFrame(updateEffects)
    window.requestAnimationFrame(updateParallax)
  }
}

function delayed(fn) {
  return () => setTimeout(fn, 1000)
}